import React from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

const colors = {
    grey: "#DCDCDC",
    darkgrey: "#A9A9A9",
    white: "#FFFFFF"
}

function About() {
    return (
        <Container className="mt-5" style={{ backgroundColor: colors.white }}>
            <Row className="justify-content-md-center">
                {/* <Image src={require("./assets/img/me.png")} className="img-fluid col-md-auto" alt="That's me" /> */}
                {/* <Image src={require("./assets/img/me.jpg")} className="img-fluid col-md-auto" alt="That's me" /> */}
                <Col>
                    <p className="font-weight-bold display-5" align="left">THAT'S ME!!!</p>
                    <p className="text-lg-left text-justify">My name is Georgios Gkekas and I am a passionate and enthusiastic technologist. I have always
                    believed that digital technology can be used for the better of the mankind and can help increase both the productivity as well as generate new
                    value with an exponential. I like to compare Information Technology and its surrounding disciplins with the "Mathematics of the 21st century",
                    just like Mathematics as a tool helped us in the previous centuries to understand the world better. </p>

                    <Row className="justify-content-md-center">
                        <Col className="col-lg-7">
                            <div className="text-separator">
                                I have a passion for distributed systems, BigData and DataScience and love evolutionary architectures since uncertainty and
                                unprecendented change rate are an integral part of technology nowadays. You will normally find me working with my colleagues
                                on cool projects for digitalizing the processes of our company and in international conferences both on stage and on screen.
                            </div>
                        </Col>
                        <Col>
                            <Table borderless hover responsive="lg" size="sm">
                                <tbody>
                                    <tr>
                                        <th>Name</th>
                                        <td>Georgios Gkekas</td>
                                    </tr>
                                    <tr>
                                        <th>Birthday</th>
                                        <td>05.03.1983</td>
                                    </tr>
                                    <tr>
                                        <th>Marital Status</th>
                                        <td>Married, two sons</td>
                                    </tr>
                                    <tr>
                                        <th>Hobbies</th>
                                        <td>Video gaming, Golf, cars</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default About;