import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faXing, faTwitter, faPinterest, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { MailIcon, LocationIcon } from '@primer/octicons-react'

import 'bootstrap-social/bootstrap-social.css';

function Social() {
    return (
        <Container>
            <hr className="my-3 hr" />
            <Row className="justify-content-md-center">
                <Col className="col-lg-4">
                    <footer className="h-25 mw-100 p-3 font-weight-bold" style={{ textAlign: "center" }}>
                        Copyright &copy; 2024 All rights reserved
                    </footer>
                </Col>
                <Col className="col-sm">
                    <Table borderless responsive="sm" size="sm">
                        <tbody> 
                            <tr>
                                <th><MailIcon /></th>
                                <td>
                                    <a target="_blank" rel="noopener noreferrer" href="mailto:info@georgiosgkekas.com">info@georgiosgkekas.com</a>
                                </td>
                            </tr>
                            <tr>
                                <th><LocationIcon /></th>
                                <td>
                                    <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/YTB9HUuijMG4SSCb7">An den Streuobstwiesen, Bad Nauheim, 61231 Germany</a>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col className="col-sm-4 col-md-offset-5">
                    <a href="https://www.linkedin.com/in/georgios-gkekas-0672474/" role="button" target="_blank" rel="noopener noreferrer" className="active btn mr-2 btn-li btn-linkedin btn-sm">
                        <FontAwesomeIcon size="2x" icon={faLinkedin} />
                    </a>
                    <a href="https://www.xing.com/profile/Georgios_Gkekas/cv" target="_blank" rel="noopener noreferrer" className="btn mr-2 btn-li btn-success btn-sm">
                        <FontAwesomeIcon size="2x" icon={faXing} />
                    </a>
                    <a href="https://twitter.com/digigek" target="_blank" rel="noopener noreferrer" className="active btn mr-2 btn-li btn-twitter btn-sm">
                        <FontAwesomeIcon size="2x" icon={faTwitter} />
                    </a>
                    <a href="https://www.pinterest.de/georgiosgkekas" target="_blank" rel="noopener noreferrer" className="active btn mr-2 btn-li btn-pinterest btn-sm">
                        <FontAwesomeIcon size="2x" icon={faPinterest} />
                    </a>
                    <a href="https://www.instagram.com/georgios.gkekas/" target="_blank" rel="noopener noreferrer" className="active btn mr-2 btn-li btn-instagram btn-sm">
                        <FontAwesomeIcon size="2x" icon={faInstagram} />
                    </a>
                </Col>
            </Row>
        </Container>
    );
}

export default Social;
