import React from 'react';

import Container from "react-bootstrap/Container";

class Experience extends React.Component {
    render() {
        return (
            <Container>
                <p className="font-weight-bold display-4" align="center">{this.props.title}</p>
                <div className="main-timeline">
                    {this.props.experience ? this.props.experience.map(function (el, index) {
                        return (
                            <div key={index} className="timeline">
                                <div className="icon"></div>
                                <div className="date-content">
                                    <div className="date-outer">
                                        <span className="date">
                                            <span className="month">{el.month}</span>
                                            <span className="year">{el.year}</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="timeline-content">
                                    <h5 className="title">{el.title}</h5>
                                    <p className="description">{el.experience}</p>
                                </div>
                            </div>
                        )
                    }) : null}
                </div>
            </Container>
        );
    }
}

export default Experience;