import React from 'react';

import Container from "react-bootstrap/Container";
import { Carousel, Image } from "react-bootstrap";

function Photos() {
    return (
        <Container >
            <Carousel >
                <Carousel.Item align="center">
                    <Image fluid className="d-block" style={{ height: "800px" }} src={require("./assets/img/IMG_2035.JPG")} alt="fintechcode_2017"></Image>
                    <Carousel.Caption>
                        <h3>Fintech:Code 2017</h3>
                        <p>Speach about DevOps culture at ING Bank</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item align="center">
                    <Image fluid className="d-block" style={{ height: "800px" }} src={require("./assets/img/IMG_2039.JPG?text=some")} alt="fintechcode_2017"></Image>
                    <Carousel.Caption>
                        <h3>Fintech:Code 2017 London</h3>
                        <p>Speach about DevOps culture at ING Bank</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item align="center">
                    <Image fluid className="d-block" style={{ height: "800px" }} src={require("./assets/img/IMG_2040.JPG")} alt="fintechcode_2017"></Image>
                    <Carousel.Caption>
                        <h3>Fintech:Code 2017 London</h3>
                        <p>Speach about DevOps culture at ING Bank</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item align="center">
                    <Image fluid className="d-block" style={{ height: "800px" }} src={require("./assets/img/IMG_3047.JPG")} alt="sw_architecture_2018"></Image>
                    <Carousel.Caption>
                        <h3>Oreilly Software Architecture 2018 New York </h3>
                        <p>Speach about the Advanced Analytics Platform of ING</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item align="center">
                    <Image fluid className="d-block" style={{ height: "800px" }} src={require("./assets/img/IMG_3320.JPG")} alt="sw_architecture_2018"></Image>
                    <Carousel.Caption>
                        <h3>Oreilly Software Architecture 2018 New York </h3>
                        <p>Speach about the Advanced Analytics Platform of ING</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item align="center">
                    <Image fluid className="d-block" style={{ height: "800px" }} src={require("./assets/img/IMG_3348.JPG")} alt="sw_architecture_2018"></Image>
                    <Carousel.Caption>
                        <h3>Oreilly Software Architecture 2018 New York </h3>
                        <p>View of One World Trade Center</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item align="center">
                    <Image fluid className="d-block" style={{ height: "800px" }} src={require("./assets/img/IMG_3157.JPG")} alt="sw_architecture_2018"></Image>
                    <Carousel.Caption>
                        <h3>Oreilly Software Architecture 2018 New York </h3>
                        <p>New York's Grand Central Station</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item align="center">
                    <Image fluid className="d-block" style={{ height: "800px" }} src={require("./assets/img/IMG_2625.JPG")} alt="general"></Image>
                    <Carousel.Caption>
                        <h3>The ING Lion</h3>
                        <p></p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item align="center">
                    <Image fluid className="d-block" style={{ height: "800px" }} src={require("./assets/img/DSC_0302.JPG")} alt="general"></Image>
                    <Carousel.Caption>
                        <h3 style={{ color: "black" }}>Having fun with Christos</h3>
                        <p></p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </Container>
    );
}

export default Photos;