import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import Container from "react-bootstrap/Container";

import './App.css';

import Menu from './Menu';
import About from './About';
import Experience from './Experience';
import Photos from './Photos';
import Social from './Social';
import Work from './Work';

export default class App extends Component {
    
    render() {
        let professional_experience = require("./data/experience.json")
        let academic_experience = require("./data/academic.json")
        return (
            <Router>
                <Menu />
                <Routes>    
                    <Route 
                        path="/photos"
                        element={
                            <Container className="mt-5" >
                                <Photos />
                            </Container>
                        }
                    />
                    <Route 
                        path="/work"
                        element={
                            <Container className="mt-5" >
                                <Work />
                            </Container>
                        }
                    />
                    <Route 
                        path="/professional_experience"
                        element={
                            <Container className="mt-5" >
                                <Experience title="Professional experience" experience={professional_experience} />
                            </Container>
                        }
                    />
                    <Route 
                        path="/academic_experience"
                        element={
                            <Container className="mt-5" >
                                <Experience title="Academic experience" experience={academic_experience} />
                            </Container>
                        }
                    />
                    <Route 
                        path="/"
                        element={
                            <Container className="mt-5">
                                <Main />
                                <About />
                            </Container>
                        }
                    />
                </Routes>
                <Social />
            </Router>
        );
    }
}

function Main() {
    return (
        <div className="main-container">
            {/* <h1>Welcome on my page</h1> */}
        </div>
    );
}