import React from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavLink from "react-bootstrap/NavLink";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";

function Menu() {
    return (
        <div>
            <Navbar bg="info" expand="lg" fixed="top">
                <Navbar.Brand href="/">Georgios Gkekas home page</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <NavLink as={Link} to="/">Home</NavLink>
                        <NavDropdown title="Experience" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/professional_experience">Professional Experience</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/academic_experience">Academic Experience</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={Link} to="/work">Work</Nav.Link>
                        <Nav.Link as={Link} to="/photos">Photos</Nav.Link>
                    </Nav>
                    {
                /**
                 
                <Form inline>
                <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                <Button variant="outline-success">Search</Button>
                </Form>
                */}
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default Menu;
