import React from 'react';
import Container from 'react-bootstrap/Container';
import { Carousel, Image, Card, Row, Col } from "react-bootstrap";

import 'bootstrap-social/bootstrap-social.css';

function Work() {
    return (
        <Container>
            <Container>
                <Card >
                    <Card.Body>
                        <Card.Title className="text-center">Here is a list of my talks in international conferences</Card.Title>
                        <blockquote className="blockquote text-center mb-0">
                            <p>
                                {' '}“A good speech should be like a woman's skirt; long enough to cover the subject and short enough to create interest.”{' '}
                            </p>
                            <footer className="blockquote-footer">
                                <cite title="TalkQuote">Winston S. Churchill</cite>
                            </footer>
                        </blockquote>
                        <Carousel>
                            <Carousel.Item align="center">
                                <a href="https://www.youtube-nocookie.com/embed/8lRLDagKogg" >
                                    <Image fluid className="d-block" style={{ height: "600px" }} src={require("./assets/img/devops.PNG")} alt="softwarearchitecture_2018"></Image>
                                </a>
                            </Carousel.Item>
                            <Carousel.Item align="center">
                                <a href="./data/data_science_platform.pptx" download>
                                    <Image fluid className="d-block" style={{ height: "600px" }} src={require("./assets/img/aa_analytics.PNG")} alt="softwarearchitecture_2018"></Image>
                                </a>
                                <Carousel.Caption>
                                    <h3 style={{ color: "black" }}>Oreilly Software Architecture 2018 New York </h3>
                                    <p style={{ color: "black" }}>Speech about the Advanced Analytics Platform of ING</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item align="center">
                                <a href="https://www.oreilly.com/library/view/oreilly-software-architecture/9781492028116/video318630.html" >
                                    <Image fluid className="d-block" style={{ height: "600px" }} src={require("./assets/img/oreilly2.jpg")} alt="softwarearchitecture_2018"></Image>
                                </a>
                                <Carousel.Caption>
                                    <h3>Oreilly Software Architecture 2018 New York </h3>
                                    <p>Speech about the Advanced Analytics Platform of ING</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item align="center">
                                <a href="./data/eai_ai.pptx" download>
                                    <Image fluid className="d-block" style={{ height: "600px" }} src={require("./assets/img/eai_ai.PNG")} alt="softwarearchitecture_2018"></Image>
                                </a>
                            </Carousel.Item>
                        </Carousel>
                    </Card.Body>
                </Card>
            </Container>
            <Container>
                <hr className="my-3 hr" />
                <Card.Title className="text-center">Here is a list of some of my publications</Card.Title>
                <blockquote className="blockquote text-center mb-0">
                    <p>
                        {' '}“Research is what I’m doing when I don’t know what I’m doing.”{' '}
                    </p>
                    <footer className="blockquote-footer">
                        <cite title="TalkQuote">Wernher Von Braun</cite>
                    </footer>
                </blockquote>
                <Row>
                    <Col>
                        <Card style={{ width: '18rem' }}>
                            <Card.Title>A classifier ensemble approach to the TV-viewer profile adaptation problem</Card.Title>
                            <Card.Body>
                                Design and development of a general technique that targets the problem of personalized TV content delivery. The user is being observed in real time by the system and the latter has the possibility to create its profile and recommend targeted TV content.
                                <p></p><Card.Link href="https://link.springer.com/article/10.1007/s13042-011-0066-4" target="_blank" rel="noopener noreferrer" >More</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '18rem' }}>
                            <Card.Title>Power aware test-data compression for scan-based testing</Card.Title>
                            <Card.Body>
                                In this paper a new approach that targets the reduction of both the test-data volume and the scan-power dissipation during testing of a digital system's cores is proposed. For achieving the two aforementioned goals, a novel algorithm that inserts some...
                                <p></p><Card.Link href="https://ieeexplore.ieee.org/document/4633432" target="_blank" rel="noopener noreferrer">More</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '18rem' }}>
                            <Card.Title>Facilitating human-centric service delivery using a pluggable service development framework</Card.Title>
                            <Card.Body>
                                Context-aware computing systems aim at facilitating human-computer interaction by providing non-obtrusive services to the end users. Such services require sophisticated software architectural frameworks which...
                                <p></p><Card.Link href="http://www.inderscience.com/offer.php?id=24525" target="_blank" rel="noopener noreferrer" >More</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container>
                <hr className="my-3 hr" />
                <Card.Title className="text-center">Here is a list of some of my projects</Card.Title>
                <blockquote className="blockquote text-center mb-0">
                    <p>
                        {' '}“I’m a greater believer in luck, and I find the harder I work the more I have of it.”{' '}
                    </p>
                    <footer className="blockquote-footer">
                        <cite title="TalkQuote">Thomas Jefferson</cite>
                    </footer>
                </blockquote>
                <Card>
                    <Card.Body>
                        <Card.Title>Establishing Data Science @ Deutsche Leasing AG</Card.Title>
                    Part of the team defining the concept for the roll-out of Data Science activities within the organisation.
                </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>Introduction of Robotics Process Automation @ Deutsche Leasing AG</Card.Title>
                    Introduction of the RPA discipline within Deutsche Leasing AG and design of the initial architecture. The automated processes have the main goal of supporting the employees in their daily activities.
                </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>Development of ECMS @ Ericsson</Card.Title>
                    ECMS stands for Ericsson Customer Management System. It is a full customer care system, with back end servers and front end clients, that satisfies all of the back-end operations of Ericsson's charging system. Currently and withing one year of productive versions, ECMS has 5 successful installations in some of the most important mobile operators in the world.
                </Card.Body>
                </Card>
            </Container>
        </Container>
    );
}

export default Work;
